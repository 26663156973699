import React from 'react';
import Helmet from 'react-helmet';
import { LayoutProps, withLayout } from '../../components/Layout';
import { LineTitle } from '../../components/LineTitle/LineTitle.component';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import { PageHeader } from '../../components/PageHeader/PageHeader.component';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import {
    AndDivider,
    Blockquote,
    CheckList,
    Constrain,
    GuthenHeadingLevel3,
    GuthenHeadingLevel4,
    LinkArrow,
    LinkText,
    pullBackExtraWide,
    pullNarrowBackExtraWide
} from '../../components/ui';
import { QuerySiteArgs } from '../../generated/graphql-types';
import styled from '../../styled-components';
import { media, mediaMax } from '../../utilities';

import checklistIllustration from './images/checklist.svg';
import orIllustration from './images/or-illustration.svg';
import noLongerFitForPurpose from './images/no-longer-fit-for-purpose.svg';
import fillAGapIllustrationWhite from '../helping-organisations-fill-a-gap-in-a-team/images/puzzle-white.svg';
import manualEffort from './images/manual-effort.svg';
import { BookACall } from '../../components/BookACall/BookACall';
import { SwellWrapper } from '../../components/SwellWrapper/SwellWrapper.component';
import { ShownOnDesktop } from '../../components/ui/ShownOnDesktop';
import socialShareImage from './images/how-we-help-organisations-improve-an-existing-process.png';
import { CTALink, DarkCTA } from '../../components/CTAs/DarkCTA';

// illustrations
import thomsonLocalIllustration from '../../../data/case-studies/thomson-local-myorderbuilder/thomson-local-illustration.svg';
import telensaIllustration from '../../../data/case-studies/telensa-planet-street-lighting-system/telensa-illustration.svg';
import { EvidencePullout } from '../../components/EvidencePullout/EvidencePullout';

const BannerHeader = styled.div`
    padding: 1.5rem 0;
    background-color: ${({ theme }) => theme.colors.deepBlue.color};
    color: #fff;

    .inner {
        ${media.l`
            margin-right: ${pullBackExtraWide};
            display: flex;
            justify-content: space-between;
        `}

        ${media.xxl`
            margin-right: ${pullNarrowBackExtraWide};
        `}
    }

    ${LineTitle} {
        font-weight: 500;
    }

    ${LinkText} {
        font-weight: normal;
    }
`;

const RelatableHeading = styled.div`
    display: flex;
    flex-direction: column-reverse;

    .illustration {
        margin-bottom: 3rem;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            margin-bottom: 2rem;
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    p {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level6.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
    }

    ${media.m`
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {
            flex: 1;
        }

        &:last-child {
            flex-direction: row-reverse;
            .illustration {
                padding: 0 7rem 0 0;
            }
        }

        .illustration {
            flex: 1.5;
            padding: 0 0 0 7rem;
        }
    `}

    ${media.xl`
        .illustration {
            flex: 1;
            margin-right: ${pullBackExtraWide};
        }

        &:last-child {
            .illustration {
                margin-right: 0;
                margin-left: ${pullBackExtraWide};
            }
        }
    `}
`;

const Header = styled.section`
    margin-bottom: 6rem;

    ${media.m`
        margin-bottom: 12rem;
    `}

    .or {
        text-align: center;
        margin: 3rem 0 6rem 0;

        img {
            width: 85px;

            ${media.m`
                width: 120px;
            `}
        }
    }
`;

const HighlightHeader = styled.h2`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    margin-bottom: 8rem;

    mark {
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
        color: ${({ theme }) => theme.colors.deepBlue.color};
        line-height: 1.2;
    }

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level1.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

const TitleHighlight = styled.div`
    position: relative;
    white-space: nowrap;
    padding-right: 1rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: -100vw;
        background-color: ${({ theme }) => theme.colors.orange.color};
        z-index: -1;
    }
`;

const WhatWeDoSection = styled.section`
    margin-bottom: 5rem;

    ${media.m`
        margin-bottom: 10rem;
    `}

    .header {
        ${TitleHighlight} {
            display: inline-block;
            align-items: center;
            margin-bottom: 1rem;

            > * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        ${media.m`
            display: flex;
            margin-bottom: 3rem;
            align-items: center;

            ${TitleHighlight} {
                flex-grow: 0;
                flex-shrink: 1;
                margin-bottom: 0;
                margin-right: 2rem;
            }

            h3 {
                margin-bottom: 0;
            }
        `}
    }

    ${mediaMax.m`
        p {
            margin: 0;
        }
    `}

    ${Blockquote} {
        ${media.l`
            transform: translateX(123px);
        `}
    }
`;

const IconList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    ${media.m`
        display: flex;
        flex-wrap: wrap;
    `}

    li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        padding-right: 4rem;
        font-weight: 500;
    }

    svg {
        width: 24px;
        height: 21px;
        margin-right: 1rem;

        & #tick {
            fill: ${({ theme }) => theme.colors.pink.color};
        }
    }
`;

const ServiceListWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    .checklist {
        display: none;
        width: 123px;

        ${media.m`
            display: block;
        `}

        ${media.l`
            margin-right: -123px;
        `}
    }
`;

const CTAWrapper = styled.div`
    margin: 0 auto;
    margin-bottom: 5rem;

    ${media.m`
        margin-bottom: 10rem;
    `}

    ${media.l`
        max-width: 85.6rem;
    `}

    ${media.xl`
        max-width: 105.6rem;
    `}
`;

interface IHelpingOrganisationsImproveAnExistingProcess extends LayoutProps {
    data: {
        site: QuerySiteArgs;
    };
}

const HelpingOrganisationsImproveAnExistingProcess = ({
    data
}: IHelpingOrganisationsImproveAnExistingProcess) => {

    return (
        <>
            <Helmet>
                <title>
                    How we help organisations improve existing processes with web or mobile applications
                </title>
                <meta
                    name="description"
                    content="How we help organisations improve existing processes with web or mobile applications"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />

                <meta
                    name="twitter:title"
                    content="Do you need to improve an existing process with software?"
                />
                <meta
                    name="twitter:description"
                    content="How we help organisations improve existing processes with web or mobile applications"
                />

                <meta
                    name="twitter:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialShareImage}`}
                />

                <meta
                    property="og:title"
                    content="Do you need to improve an existing process with software?"
                />

                <meta
                    property="og:description"
                    content="How we help organisations improve existing processes with web or mobile applications"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }/helping-organisations-improve-an-existing-process`}
                />
                <meta
                    property="og:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialShareImage}`}
                />
            </Helmet>
            <FixedMasthead />

            <BannerHeader>
                <Constrain type="narrow">
                    <div className="inner">
                        <LineTitle color="white">
                            How we help organisations
                        </LineTitle>

                        <ShownOnDesktop>
                            <LinkArrow
                                to="/helping-organisations-fill-a-gap-in-a-team"
                                color="white"
                                arrowColor="pink"
                            >
                                <LinkText underlineColor="pink">
                                    You want to fill a gap in your team with
                                    proven expertise.
                                </LinkText>
                            </LinkArrow>
                        </ShownOnDesktop>
                    </div>
                </Constrain>
            </BannerHeader>

            <PageHeader
                highlightColor="electricBlue"
                title="You want to build software to improve an existing process"
            />

            <Constrain type="narrow">
                <Header>
                    <RelatableHeading>
                        <div>
                            <h2>
                                You have an existing process that requires a lot
                                of manual effort
                            </h2>
                            <p>
                                You’re getting things done, but maybe the way
                                you’re doing it is error prone, inefficient or
                                unreliable?
                            </p>
                        </div>
                        <div className="illustration">
                            <img
                                src={manualEffort}
                                alt="Illustration of convoluted manual processes resulting in errors"
                            />
                        </div>
                    </RelatableHeading>

                    <div className="or">
                        <img src={orIllustration} alt="Or" />
                    </div>

                    <RelatableHeading>
                        <div>
                            <h2>
                                Your existing technology solution is no longer
                                fit for purpose
                            </h2>
                            <p>
                                Maybe it's coming to its end of life, is too
                                expensive to maintain or hard to adapt to
                                changing needs?
                            </p>
                        </div>
                        <div className="illustration left">
                            <img
                                src={noLongerFitForPurpose}
                                alt="Illustration of old computer anchoring a boat down"
                            />
                        </div>
                    </RelatableHeading>
                </Header>

                <HighlightHeader>
                    <mark>
                        Let us show you how we'll build you a web or mobile
                        application to fix that
                    </mark>
                </HighlightHeader>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Solve the right problem
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Arriving at a solution that's an effective way of
                            improving the process
                        </h3>
                    </div>

                    <p>
                        Software often fails, not because the technology was
                        created poorly, but because it solves the wrong problem
                        or the problem it was supposed to solve wasn't well
                        understood. We'll dedicate enough time to understand the
                        problem by visualising the existing process and
                        identifying pain points with stakeholder interviews and
                        user research. Following, we’ll capture a range of
                        solutions by involving those closest to the problem to
                        collaborate on design and ideation, taking concepts into
                        prototyping for an early round of user testing and
                        refinement.
                    </p>

                    <Blockquote>
                        If I had an hour to solve a problem I'd spend 55 minutes
                        thinking about the problem and five minutes thinking
                        about solutions.
                        <cite>Albert Einstein</cite>
                    </Blockquote>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'User research',
                                'Prototyping',
                                '\'As is\' mapping',
                                'Incremental delivery',
                                'Risk mapping',
                                'Collaborative design',
                                'Design sprint'
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={thomsonLocalIllustration}
                        linkTo="/case-studies/thomson-local-myorderbuilder"
                        text="How we ran contextual enquiry sessions to ensure we understood Thomson Local's users' needs."
                    />    

                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Maximising budget
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Identifying the core value and the shortest path to
                            delivering it
                        </h3>
                    </div>

                    <p>
                        We'll prioritise outcomes, getting working software into
                        users hands as early as possible to make sure we're
                        creating the anticipated value. As we build
                        incrementally, we're able to discover new user needs and
                        adjust the plan as we go. In other words: start with a
                        plan, test that plan as early as possible, and be
                        prepared to change when new opportunities arise.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'Incremental delivery',
                                'User testing',
                                'User experience design',
                                'Story mapping',
                                'Prioritisation',
                                'Cost of delay'
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Built for business agility
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Software that does what is intended, is high quality
                            and easy to adapt
                        </h3>
                    </div>

                    <p>
                        There are many known strategies for developing high
                        quality software and yet surprisingly few organisations
                        apply them. Luckily for you, we know our onions. Or more
                        specifically, we know by applying certain technical
                        practices, and by automating as much of the quality
                        assurance process as possible, we can build mobile and
                        web apps that deliver exactly what they’re intended to,
                        are free from defects and easy to change.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'BDD',
                                'TDD',
                                'Web applications',
                                'iOS and Android apps',
                                'React',
                                'React Native',
                                'Node',
                                'JavaScript',
                                'Example mapping'
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Sustain and improve
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Software that will continue to stay relevant to your
                            business as it changes
                        </h3>
                    </div>

                    <p>
                        Software by definition is malleable - it can moulded
                        into new shapes to meet changing needs. But left alone
                        it will decay, becoming unstable and insecure,
                        eventually succumbing to failure; and as your business
                        needs change it will cease to adequately serve you. This
                        is why you'll find us continuously improving your
                        software. We’ll observe your users and make the
                        necessary changes so that it continues to stay relevant.
                        We'll also sweat the small stuff, enabling business
                        continuity by keeping things updated and stable.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'SLA',
                                'Documentation',
                                'Test suites',
                                'Continuous improvement',
                                'User research',
                                'Support'
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Flying the nest
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Helping you to continue the progress we’ve made
                            together
                        </h3>
                    </div>

                    <p>
                        Eventually you may want to take responsibility for the
                        continued evolution of your software. We’ll help you to
                        continue the progress we’ve made together by bringing
                        your employees into the product team, sharing knowledge
                        by pair programming and co-writing living documentation
                        that provides information that’s current, accurate and
                        easy to understand.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'Pair programming',
                                'Living documentation',
                                'Specification by example',
                                'Behavior-driven development',
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={telensaIllustration}
                        linkTo="/case-studies/telensa-planet-street-lighting-system"
                        text="How we delivered the first iteration and enabled Telensa to continue developing PLANet3 "
                    />  

                </WhatWeDoSection>

                <CTAWrapper>
                    <DarkCTA>
                        <div>
                            <h4>We can also help you&hellip;</h4>
                            <p>
                                <CTALink>
                                    <LinkArrow
                                        to="/helping-organisations-fill-a-gap-in-a-team"
                                        arrowColor="electricBlue"
                                    >
                                        <LinkText underlineColor="electricBlue">
                                            Fill a gap in your team with
                                            proven expertise.
                                        </LinkText>
                                    </LinkArrow>
                                </CTALink>
                            </p>
                        </div>

                        <img
                            src={fillAGapIllustrationWhite}
                            alt="Illustration of a puzzle with a missing piece"
                        />
                    </DarkCTA>
                </CTAWrapper>
            </Constrain>

            <SwellWrapper
                title="Next steps."
                subtitle="Speak with one of our founders"
            >
                <BookACall
                    calendlyUrl="https://calendly.com/we-make-waves/organisations-improve-a-process?background_color=003d4c&text_color=ffffff&primary_color=00eae9"
                    pageUrl="helping-organisations-improve-an-existing-process"
                />
            </SwellWrapper>

            <CallToContact />
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default withLayout(HelpingOrganisationsImproveAnExistingProcess);
